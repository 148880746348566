<template>
  <div class="conference-container" ref="conference">
    <van-sticky type="primary">
      <head-bar :title="'日程详情'" @onClickLeft="onClickLeft" />
    </van-sticky>
    <div class="conference-swiper">
      <div class="swiper-wrapper">
        <!-- 页面1 -->
        <!-- -->
        <div
            class="swiper-slide swiper-item"
            v-if="groupList[group.groupLink] && groupList[group.groupLink].name !='岳麓论坛'"
        >
          <div class="scroll-swiper-container scroll-swiper-container-one">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-item">
                <div class="page-one-container">
                  <div class="logo">
                    <img
                        src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202408241925215053712.png"
                        alt=""
                    />
                  </div>
                  <div class="name">
                    {{ groupList[group.groupLink].name }}
                  </div>
                  <div class="company" v-if="groupList[group.groupLink]">
                    <span class="label">指导单位:</span>
                    <div class="list">
                      <span
                          v-for="(item, index) in groupList[group.groupLink].zdOrganizer"
                          :key="index"
                      >
                        <div v-for="(b, i) in item.split('br')" :key="i">
                          {{ b }}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="company" v-if="groupList[group.groupLink]">
                    <span class="label">主办单位:</span>
                    <div class="list">
                      <span
                          v-for="(item, index) in groupList[group.groupLink].organizer"
                          :key="index"
                      >
                        <div v-for="(b, i) in item.split('br')" :key="i">
                          {{ b }}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="company" v-if="groupList[group.groupLink]">
                    <span class="label">承办单位:</span>
                    <div class="list">
                      <span
                          v-for="(item, index) in groupList[group.groupLink].undertaker"
                          :key="index"
                      >
                        <div v-for="(b, i) in item.split('br')" :key="i">
                          {{ b }}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                      class="company"
                      v-if="
                      groupList[group.groupLink] &&
                      groupList[group.groupLink].coOrganizer.length > 0
                    "
                  >
                    <span class="label">协办单位:</span>
                    <div class="list">
                      <span
                          v-for="(item, index) in groupList[group.groupLink].coOrganizer"
                          :key="index"
                      >
                        <div v-for="(b, i) in item.split('br')" :key="i">
                          {{ b }}
                        </div>
                      </span>
                    </div>
                  </div>
                  <!-- <div
                    class="company"
                    v-if="
                      groupList[group.groupLink] &&
                      groupList[group.groupLink].zxOrganizer &&
                      groupList[group.groupLink].zxOrganizer.length > 0
                    "
                  >
                    <span class="label">执行单位:</span>
                    <div class="list">
                      <span
                        v-for="(item, index) in groupList[group.groupLink].zxOrganizer"
                        :key="index"
                      >
                        <div v-for="(b, i) in item.split('br')" :key="i">
                          {{ b }}
                        </div>
                      </span>
                    </div>
                  </div> -->
                  <!-- <div
                    class="company"
                    v-if="
                      groupList[group.groupLink] &&
                      groupList[group.groupLink].zcOrganizer &&
                      groupList[group.groupLink].zcOrganizer.length > 0
                    "
                  >
                    <span class="label">支持单位:</span>
                    <div class="list">
                      <span
                        v-for="(item, index) in groupList[group.groupLink].zcOrganizer"
                        :key="index"
                      >
                        <div v-for="(b, i) in item.split('br')" :key="i">
                          {{ b }}
                        </div>
                      </span>
                    </div>
                  </div> -->
                  <!-- <div class="contact" v-if="groupList[group.groupLink].test">
                      专场联络员： {{ groupList[group.groupLink].test.name }} {{ groupList[group.groupLink].test.num }}
                    </div> -->
                  <!-- <div class="contact" v-if="groupList[group.groupLink].desc">
                      专场联络员： {{ groupList[group.groupLink].desc }}
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 页面2 v-if="groupList[group.groupLink]&&groupList[group.groupLink].poster !== ''" -->
        <!--   v-if="group.name === 'AI致远 产业图新'" -->
        <!-- <div
          class="swiper-slide swiper-item"
          v-if="
            (group.name && group.name === '岳麓论坛') ||
            group.name === '峰会新十年  湘商新征程' ||
            group.name === '文化智核  科技新篇' ||
            group.name === '投资新趋势 产业新格局' ||
            group.name === '投资新趋势 产业新格局' ||
            group.name.indexOf('AI驱动 数实融合') != -1 ||
            group.name === '产业互联网 智汇长沙城'
          "
        > -->
        <div
            class="swiper-slide swiper-item"
            v-if="
          group.name &&
            ((group.name && group.name === '岳麓论坛') ||
            group.name === '峰会新十年  湘商新征程' ||
            group.name === '文化智核  科技新篇' ||
            group.name === '投资新趋势 产业新格局' ||
            group.name === '投资新趋势 产业新格局' ||
            group.name.indexOf('AI驱动 数实融合') != -1 ||
            group.name === '产业互联网 智汇长沙城') && groupList[group.groupLink].desc != ''
          "
        >
          <div class="scroll-swiper-container scroll-swiper-container-two">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-item">
                <div class="two-head">
                  {{ groupList[group.groupLink].name }}
                </div>
                <div class="two-head">
                  主题：{{ groupList[group.groupLink].theme }}
                </div>
                <div class="two-img" v-if="groupList[group.groupLink].poster">
                  <img
                      v-if="groupList[group.groupLink].poster"
                      :src="groupList[group.groupLink].poster"
                      alt=""
                  />
                  <img
                      v-else
                      src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306061908363536815.png"
                      alt=""
                  />
                </div>
                <div
                    class="two-desc"
                    v-if="groupList[group.groupLink].desc"
                    v-html="
                    groupList[group.groupLink] ? groupList[group.groupLink].desc : ''
                  "
                ></div>
                <div class="contact" v-if="group.name !== '岳麓论坛'">
                  {{
                    groupList[group.groupLink].noSign
                        ? "承办单位联络员："
                        : "论坛联络人："
                  }}{{ groupList[group.groupLink].contact.name }}
                  {{ groupList[group.groupLink].contact.phone }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="footer" v-if="$store.state.miceInfo.createBy && groupList[group.groupLink].schedule.length &&groupList[group.groupLink].schedule.length === 0">
              <div class="wraper" @click="question">
                峰会满意度调查 <van-icon style="margin-left: 10px" name="arrow" />
              </div>
            </div> -->
        </div>

        <!-- 嘉宾 -->
        <!-- <div class="swiper-slide swiper-item" v-if="group.name !== '岳麓论坛'"> -->
        <div class="swiper-slide swiper-item" v-if="false">
          <div class="scroll-swiper-container scroll-swiper-container-one">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-item">
                <div class="page-one-container">
                  <div class="user-title-wrapper">
                    <div class="user-title">
                      <img
                          src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306072000232167646.png"
                          alt=""
                      />
                      <div>嘉宾</div>
                    </div>
                    <div class="sub-title">排名不分先后</div>
                  </div>
                  <div v-for="(item, index) in groupList[group.groupLink].users">
                    <div class="user-list-wrapper">
                      <div
                          class="user-item-item"
                          :class="[index % 2 === 0 ? '' : 'right']"
                          :style="{
                          background:
                            index % 2 !== 0
                              ? 'linear-gradient(to left,rgb(62, 84, 172), rgba(73, 66, 228,0))'
                              : 'linear-gradient(to left, rgba(16,13,67, 0), rgba(16,13,67, 1))',
                          borderRadius:
                            index % 2 === 0 ? '100px 0 0 100px' : '0 100px 100px 0',
                        }"
                      >
                        <!--      ? 'linear-gradient(to right, rgba(0, 157, 255, 1), #192f6a);' -->
                        <div class="img-wrapper">
                          <img
                              src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306072000232167646.png"
                              alt=""
                          />
                        </div>
                        <div class="txt-wrapper">
                          <div
                              class="username"
                              :style="{
                              textAlign: index % 2 === 0 ? 'left' : 'right',
                              marginLeft: index % 2 === 0 ? '0' : '60%',
                            }"
                          >
                            {{ item.name }}
                          </div>
                          <div
                              class="desc"
                              :style="{
                              textAlign: index % 2 === 0 ? 'left' : 'right',
                            }"
                          >
                            {{ item.company }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 页面3  -->
        <!-- group.name === '岳麓论坛' ||  -->
        <!-- v-if="
            group.name === 'AI致远 产业图新' ||
            group.name.indexOf('新材料') !== -1 ||
            group.name === '智能智造 领航未来' || 
            group.name === '岳麓论坛'
          " -->
        <div
            class="swiper-slide swiper-item"
        >
          <div class="scroll-swiper-container scroll-swiper-container-three">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="two-head">会议议程</div>
                <div class="schedule-container" v-if="groupList[group.groupLink]">
                  <!-- <div class="address">
                      <span>地点：{{ group.address || "" }}</span>
                      <div
                        style="margin-top: 15px"
                        v-if="groupList[group.groupLink].host.name"
                      >
                        <span>主持人：</span>
                        <span v-html="groupList[group.groupLink].host.name"></span>
                      </div> -->
                  <!-- <span v-for="item in groupList[group.groupLink].host.position" :key="item">{{ item }}</span> -->
                  <!-- <span v-if="groupList[group.groupLink].host.name">{{
                        groupList[group.groupLink].host.position.join("、")
                      }}</span> -->
                  <!-- </div> -->
                  <!-- 地址 -->
                  <div
                      :class="{
                      'sche-wraper': true && item.titleType !== 'center' ,
                      'sche-after': !item.module && item.titleType !== 'center' && group.name !== '岳麓论坛',
                      'sche-separate': item.isSeparate === 1 && item.titleType !== 'center' && group.name !== '岳麓论坛',
                      'sche-separate-last': item.isSeparate === 2 && item.titleType !== 'center' && group.name !== '岳麓论坛',
                      'title-center' : item.titleType === 'center',
                      'custom-module': groupList[group.groupLink].schedule[index -1] && groupList[group.groupLink].schedule[index -1].titleType && groupList[group.groupLink].schedule[index -1].titleType === 'center',
                      'bg-2' : group.name !== '岳麓论坛' ? index % 2 !== 0 : item.titleType !== 'center',
                      'no-before': group.name === '岳麓论坛'
                    }"
                      v-for="(item, index) in groupList[group.groupLink].schedule"
                      :key="index"
                  >
                    <!-- <div class="center-title" v-if="item.titleType='center'">
                      {{item.title}}
                    </div> -->
                    <!-- 时间 -->
                    <template >
                      <div style="width: 100px; height: 24px;display:flex;align-items: center;justify-content: center;" v-if="item.titleType !== 'center' && group.name !== '岳麓论坛'">
                        <div class="time" v-if="item.date">{{ item.date }}</div>
                      </div>
                      <div class="content">
                        <template v-if="!item.module">
                          <!-- 内容 -->
                          <div class="theme" v-if="item.title">
                            <div class="type">{{ item.type }}</div>
                            <div class="title" v-html="item.title"></div>
                          </div>
                          <!-- 主持人 -->
                          <div
                              class="title-2024"
                              style="padding: 5px 8px 0; font-weight: bold"
                              v-if="item.tableTalk && item.titleType !== 'center'"
                          >
                            <span>主</span>
                            <span>持</span>
                            <span>人:</span>
                          </div
                          >
                          <div class="people" v-if="item.host.name">
                            <div class="name guest-name" v-if="item.host.name">
                              <span
                                  v-for="(name, i) in item.host.name.split('')"
                                  :key="i"
                              >{{ name }}</span
                              >
                            </div>
                            <div class="position">
                              <span
                                  v-for="(position, i) in item.host.position"
                                  :key="i"
                              >{{ position }}</span
                              >
                            </div>
                          </div>
                          <!-- 嘉宾 -->
                          <div
                              class="title-2024"
                              style="padding: 5px 8px 0; font-weight: bold"
                              v-if="item.tableTalk && item.people.length !== 0"
                          >
                            <span>嘉</span>
                            <span>宾:</span>
                          </div
                          >
                          <div
                              class="people"
                              v-for="(people, posi) in item.people"
                              :key="posi"
                          >
                            <div class="name guest-name" v-if="people.name">
                              <span v-for="(name, i) in people.name.split('')" :key="i">{{
                                  name
                                }}</span>
                            </div>
                            <div class="position" :style="groupList[group.groupLink].name==='AI驱动 数实融合' &&people.name==='' ?{marginLeft:'8px'}:{}">
                              <span v-for="(position, i) in people.position" :key="i">{{
                                  position
                                }}</span>
                            </div>
                          </div>
                        </template>

                        <div class="module" v-if="item.module">
                          <span>{{ item.module.name }}</span>
                          <div style="margin-top: 15px">
                            <span>{{ item.module.host.name ? "主持人：" : "" }}</span>
                            <span v-html="item.module.host.name"></span>
                            <span style="display: block">{{
                                item.module.host.position.join("、")
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div
                      class="more"
                  >
                    持续更新中...
                  </div>
                </div>
                <!-- <div class="footer" v-if="$store.state.miceInfo.createBy">
                    <div class="wraper" @click="question">
                      峰会满意度调查 <van-icon style="margin-left: 10px" name="arrow" />
                    </div>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <float-menu :conferencePage="true" />
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper.js";
import "swiper/css/swiper.css";
import floatMenu from "@/components/floatMenu/index";
import headBar from "@/components/headBar/headBar";
export default {
  name: "",
  components: { floatMenu, headBar },
  data() {
    return {
      swiper: null,
      pageTwo: null,
      pageOne: null,
      pageThree: null,
      schedule: [
        {
          date: "08:57-09:40",
          title: "2023互联网岳麓峰会开幕式",
          type: "",
          people: [],
        },
        {
          date: "09:40-09:58",
          title: "数字经济 生态铸未来",
          type: "主题演讲",
          people: [
            {
              name: "史耀宏",
              position: ["华为技术有限公司副总裁", "华为中国区云计算总裁"],
            },
          ],
        },
        {
          date: "10:00-10:15",
          title: "数字经济 生态铸未来",
          type: "主题演讲",
          people: [
            {
              name: "史耀宏",
              position: ["华为技术有限公司副总裁", "华为中国区云计算总裁"],
            },
          ],
        },
      ],
    };
  },
  computed: {
    group() {
      return this.$store.state.groupInfo.group;
    },
    groupList() {
      return this.$store.state.groupInfo.groupList;
    },
  },
  mounted() {
    this.$utils.setStorage("location", window.location.href);
    this.getGroupList();
    document.addEventListener('copy',(event)=>{
      event.preventDefault();
    })
  },
  beforeDestroy(){
    document.removeEventListener('copy')
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    question() {
      // window.location.href = 'https://www.wjx.cn/vj/tUAk5NH.aspx'
      this.$router.push(
          `/questionnaire/default/5d83aa6bcbd45da1e33bc1566d48f95f/${this.$route.params.miceLink}`
      );
    },
    initSwiper() {
      this.$nextTick(() => {
        this.swiper = new Swiper(".conference-swiper", {
          direction: "vertical",
        });
        this.pageTwo = new Swiper(".scroll-swiper-container-two", {
          // 子swiper
          direction: "vertical",
          nested: true,
          // resistanceRatio: 1,
          slidesPerView: "auto",
          freeMode: true,
        });
        this.pageOne = new Swiper(".scroll-swiper-container-one", {
          // 子swiper
          direction: "vertical",
          nested: true,
          // resistanceRatio: 1,
          slidesPerView: "auto",
          freeMode: true,
        });
        this.pageThree = new Swiper(".scroll-swiper-container-three", {
          // 子swiper
          direction: "vertical",
          nested: true,
          // resistanceRatio: 1,
          slidesPerView: "auto",
          freeMode: true,
        });
      });
    },
    async getGroupList() {
      if (this.$store.state.groupInfo.groupArray.length === 0) {
        let result = await this.$http.get(
            this.$baseURL.index.getGroupList() +
            "?miceLink=" +
            this.$store.state.miceInfo.miceLink,
            1
        );
        this.$store.commit("groupInfo/SET_GROUPARRAY", result.data);
        if (!this.$store.state.groupInfo.group.groupLink) {
          if (this.$route.params.groupLink) {
            const index = this.$store.state.groupInfo.groupArray.findIndex((item) => {
              return (item.groupLink = this.$route.params.groupLink);
            });
            if (index !== -1) {
              this.$store.commit(
                  "groupInfo/SET_GROUP",
                  this.$store.state.groupInfo.groupArray[index]
              );
            }
          }
        }
        this.initSwiper();
      } else {
        this.initSwiper();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.conference-container {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* IE/Edge */
  user-select: none;         /* 标准语法 */
  height: 100vh;
  background: no-repeat center/cover
  url("https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202408261153416952815.jpg");
  overflow: hidden;
  .conference-swiper {
    height: calc(100% - 1.266rem);
    width: 100%;
    overflow: hidden;
    .swiper-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: auto;
      width: 100%;
      color: #fff;
      font-size: 15px;
      .head-banner {
        height: 4.8rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .logo {
          height: 55px;
          width: 55px;
        }
        .text {
          margin-top: 15px;
          height: 17px;
        }
      }
      p,
      h4 {
        color: #efefef;
        width: 75%;
        text-align: center;
        font-weight: bold;
      }
      h4 {
        font-size: 12px;
        margin: 5px 0;
        font-weight: normal;
      }
      .two-head {
        font-size: 18px;
        font-weight: bold;
        height: 2.2rem;
        line-height: 2.2rem;
        text-align: center;
      }
      .two-img {
        border: 3px solid #fff;
        padding: 5px;
        width: 85%;
        background: rgb(25, 42, 132);
        img {
          height: 100%;
          width: 100%;
        }
      }
      .two-desc {
        margin-top: 30px;
        width: 85%;
        font-size: 13px;
        text-indent: 0.7rem;
        background: rgba(15, 60, 123, 0.6);
        padding: 10px;
        border-radius: 8px;
        line-height: 1.7;
        /deep/p {
          margin: 0;
        }
      }
      .three-img,
      .four-img {
        width: 85%;
      }
      .contact {
        padding: 10px;
        border: 3px solid #fff;
        background: rgb(25, 42, 132);
        font-size: 16px;
        margin-top: 20px;
        font-weight: bold;
      }
    }
  }
}
.scroll-swiper-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .swiper-slide {
    height: auto;
    padding: 15px 0;
    box-sizing: border-box;
  }
}
.schedule-container {
  width: 100%;
  padding: 0 20px;
  font-size: 13px;
  .address {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #1672ae;
    color: #fff;
    border-radius: 6px;
    text-align: center;
  }
  .sche-wraper {
    // background-color: rgba(15, 60, 123, 0.8);
    // padding: 8px 4px;
    //  background-color: rgba(15, 60, 123, 0.8);
    background: linear-gradient(to right, #1860c5 , #2b9348 );
    // background: linear-gradient(to right, #1860c5, #baf13a);

    position: relative;
    margin: 4px 0;
    padding: 15px 0;
    // padding-top: 5px;
    display: flex;
    padding-bottom: 120px;
    border-radius: 4px;
    .module {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #1672ae;
      color: #fff;
      border-radius: 6px;
      text-align: center;
      span:nth-of-type(1) {
        font-weight: bold;
        font-size: 14px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 86px;
      height: 9px;
      width: 9px;
      background: #219ebc;
      border-radius: 50%;
    }
    &:nth-last-child(1)::after {
      border: none;
    }
    .time {
      position: relative;
      width: 90px;
      padding: 4px 0;
      // background: #1672ae;
      border-radius: 4px;
      text-align: center;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -7px;
      height: 24px;
      // &::before {
      //   content: "";
      //   position: absolute;
      //   height: 10px;
      //   width: 10px;
      //   background: #1672ae;
      //   border-top-right-radius: 2px;
      //   right: -4px;
      //   z-index: -1;
      //   transform: rotateZ(45deg);
      // }
    }
    .content {
      font-size: 12px;
      flex: 1;
      margin-left: 5px;
      margin-top: -5px;
      .theme {
        // border: 1px solid #1672ae;
        border-radius: 5px;
        padding: 6px 8px;
        .title {
          font-weight: bold;
        }
        .type {
          font-size: 10px;
        }
      }
      .people {
        display: flex;
        font-size: 12px;
        margin-top: 10px;
        display: flex;
        // align-items: center;
        // height: 100%;
        .name {
          padding-left: 8px;
          margin-top: -1px;
          width: 55px;
          // height: 20px;
        }
        .position {
          display: flex;
          flex: 1;
          flex-direction: column;
          font-size: 11px;
          // height: 20px;
        }
      }
    }
  }
  .sche-after {
    padding: 10px 0;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 90px;
      border-left: 1px solid #219ebc;
      height: calc(100% + 21px);
    }
  }
  .sche-separate {
    padding-bottom: 40px;
    &::after {
      display: none;
    }
  }
  .sche-separate-last {
    padding-bottom: 40px;
    &::before {
      display: none;
    }
  }
}
.footer {
  padding: 20px;
  width: 100%;
  .wraper {
    background: #1672ae;
    border-radius: 20px;
    padding: 10px 0;
    display: flex;
    float: right;
    justify-content: center;
    align-items: center;
    width: 150px;
    // border: 1px solid #fff;
    font-size: 13px;
  }
}
.page-one-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 265px;
    }
  }
  .name {
    border: 3px solid #fff;
    font-size: 15px;
    font-weight: bold;
    padding: 5px;
    width: 80%;
    height: 60px;
    background: rgb(25, 42, 132);
    text-align: center;
    line-height: 44px;
  }
  .company {
    width: 80%;
    margin: 10px 0;
    display: flex;
    font-size: 13px;
    .label {
      width: 65px;
      display: inline-block;
      margin-top: 3px;
    }
    .list {
      width: calc(100% - 65px);
      display: flex;
      line-height: 2;
      flex-direction: column;
      span {
        div {
          text-wrap: nowrap;
        }
      }
    }
  }
}

.guest-name {
  text-align: justify;
  text-justify: inter-ideograph;
  width: 50px !important;
  text-align-last: justify;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  // span {
  //   display: inline-block;
  //   padding-left: 100%;
  //   &::after{content:'1';width: 100%;display: inline-block;overflow: hidden;height: 0;}
  // }
}

.user-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // .username {
  //   font-size: 15px;
  //   font-weight: bold;
  //   margin-right: 10px;
  // }
  .company {
  }
}

.user-title-wrapper {
  .user-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 15px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
}

.user-list-wrapper {
  .user-item-item {
    // background-color: #1672ae;
    // background: linear-gradient(to right, #192f6a, #0a192f ,#192f6a);
    // background: linear-gradient(to right, #6177b6,#0b1ca1);
    width: 90vw;
    // margin-left: 5%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    // border-radius: 4px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    // background: linear-gradient(to right,rgb(62, 84, 172), rgba(73, 66, 228,0));
    .img-wrapper {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .txt-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      width: 65%;
      // background: linear-gradient(to right, rgba(0, 157, 255, 0), #192f6a);
      .username {
        font-size: 14px;
        width: 40%;
        font-weight: 500;
        padding: 4px 12px;
      }
      .desc {
        // border-top: 1px solid #ccc;
        font-size: 12px;
        color: #fff;
        width: 100%;
        // background: linear-gradient(to right, rgba(0, 157, 255, 0), #192f6a);
        // padding: 12px;
        padding: 2px 12px;
        position: relative;
      }
    }
  }
}

.title-2024 {
  width: 60px;
  display: flex;
  justify-content: space-between;
}

.right {
  flex-direction: row-reverse;
  img {
    margin-left: 8px;
    margin-right: 0;
  }
  .text-wrapper {
    margin-right: 8px !important;
    margin-left: 0 !important;
    align-items: flex-end !important;
    .username {
      background: linear-gradient(to left, rgba(0, 157, 255, 0), #192f6a) !important;
    }
    .desc {
      background: linear-gradient(to left, rgba(0, 157, 255, 0), #192f6a) !important;
    }
  }
}

.sub-title {
  font-size: 12px;
  text-align: center;
}

.more {
  padding: 10px;
  text-align: center;
}

.title-center {
  text-align: center;
  font-weight: 600;
  // background: linear-gradient(to right, rgba(0, 157, 255, 1), #192f6a);
  background: linear-gradient(to right, #1860c5 , #2b9348 );
  // background: skyblue;
  padding: 5px 8px;
  position: relative;
  z-index: 1000;
  border-radius: 4px;
}

.bg-2 {
  // background: rgba(15, 60, 123, 0.6) !important;
  background: rgba(30, 53, 154, 1) !important;

}

.custom-module {
  // background-color: rgba(15, 60, 123, 0.8);
  background: linear-gradient(to right, rgba(0, 157, 255, 1), #192f6a);
  border-radius: 8px;
  padding-bottom: 0 !important;
  padding-top: 5px;
  // background: linear-gradient(to right, rgba(16, 13, 67, 0), rgb(16, 13, 67));;
}
.no-before {
  &::before {
    display: none;
  }
  padding: 8px 0 !important;
}
</style>
